import classnames from "classnames";

export type ButtonStyle = "primary" | "secondary" | "danger";

export default function buttonStyleClasses(style: ButtonStyle) {
  return classnames(
    "inline-block select-none whitespace-nowrap rounded border border-solid px-3 py-1 text-center align-middle font-normal no-underline transition-colors disabled:pointer-events-none disabled:opacity-70",
    {
      "border-blue-600 bg-blue-600 text-white hover:border-sky-800 hover:bg-blue-700":
        style === "primary",
      "border-gray-500 bg-gray-500 text-white hover:border-gray-700 hover:bg-gray-600":
        style === "secondary",
      "border-red-600 bg-red-600 text-white hover:border-red-800 hover:bg-red-700":
        style === "danger",
    },
  );
}
