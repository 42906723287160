import classnames from "classnames";
import { Link } from "react-router-dom";

export default function Navbar() {
  const navClasses = classnames(
    "relative flex flex-wrap items-center justify-between py-2",
    {
      "bg-emerald-700": window.gon.application_scheme === "modernwallet",
      "bg-violet-700": window.gon.application_scheme === "modernbagelry",
    },
  );

  return (
    <nav className={navClasses}>
      <div className="mx-auto flex w-full items-center justify-between px-3">
        <Link
          to="/"
          className="whitespace-nowrap py-1 text-xl text-white no-underline"
        >
          {window.gon.application_title}
        </Link>
      </div>
    </nav>
  );
}
